import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StaffMetaDataAPI } from "src/service/staffMetaData/StaffMetaData";

export const getAllStaffMetaData = createAsyncThunk("staffs/getAll", async () => {
    const response = await StaffMetaDataAPI.getBriefAllStaffMetaData();

    return response.data?.data?.map((item) => ({
        ...item,
        value: item.id,
        label: item.code,
    }));
});

const staffMetaDataReducer = createSlice({
  name: "staffs",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getAllStaffMetaData.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default staffMetaDataReducer.reducer;
