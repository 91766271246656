import { DEPARTMENT_URL } from "src/constant/constantURL";
import { get, post, put } from "../axios";

export const departmentAPI = {
  createDepartment(params) {
    return post(`${DEPARTMENT_URL}`, params, true);
  },
  UpdateDepartment(id, data) {
    return put(`${DEPARTMENT_URL}/${id}`, data, true);
  },
  getAllDepartment(params) {
    return get(DEPARTMENT_URL, params);
  },
  getDepartment(id) {
    return get(`${DEPARTMENT_URL}/${id}`);
  },
  deactivateDepartment(id) {
    return post(`${DEPARTMENT_URL}/${id}/deactivate`);
  },
  activateDepartment(id) {
    return post(`${DEPARTMENT_URL}/${id}/activate`);
  },
};
