import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-datasheet-grid/dist/style.css'
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table/react-table.css";
import './scss/style.scss';
import "./css/main.css"
import "./css/custom.css"
import TheLoader2 from './containers/TheLoader2';
import { ConfigProvider } from 'antd';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
  // <TheLoader2/>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./pages/login/Login'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));

// Pages

class App extends Component {

  render() {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4479c5",
            colorInfo: "#4479c5",
          },
          components: {
            Button: {
              defaultColor: "rgb(112, 126, 147)",
              colorBgContainerDisabled: "rgb(242, 246, 251)",
              colorTextDisabled: "rgb(199, 210, 227)",
              defaultBorderColor: "#98A2B3",
            },
            Input: {
              colorBorder: "#98A2B3",
              colorTextPlaceholder: "rgb(112, 126, 147)"
            },
            InputNumber: {
              colorBorder: "#98A2B3",
              colorTextPlaceholder: "rgb(112, 126, 147)"
            },
            Form: {
              colorBorder: "#98A2B3",
            },
            Select: {
              colorBorder: "#98A2B3",
              colorTextPlaceholder: "rgb(112, 126, 147)"
            },
            DatePicker: {
              colorBorder: "#98A2B3",
              colorTextPlaceholder: "rgb(112, 126, 147)"
            },
            Pagination: {
              itemSize: 32,
              itemSizeSM: 32,
            },
            Form: {
              itemMarginBottom: 0,
            }
          },
        }}
      ><BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/privacyPolicy" name="Privacy Policy" render={props => <PrivacyPolicy {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter></ConfigProvider>
    );
  }
}

export default App;
