import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { departmentAPI } from "src/service/department/department";

export const getAllDepartments = createAsyncThunk("department/getAll", async () => {
  const response = await departmentAPI.getAllDepartment({ size: 1000, page: 0 });
  return response.data?.data?.map((item) => ({
    ...item,
    value: item.code,
    label: item.name,
  }));
});

const departmentReducer = createSlice({
  name: "departments",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getAllDepartments.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default departmentReducer.reducer;