import { EXPORT_META_DATA, STAFF_META_DATA } from "src/constant/constantURL";
import { del, downloadFile, get, post, put, putFormData, postUploadImage } from "../axios";

export const StaffMetaDataAPI = {
    getAllStaffMetaData(params) {
        return get(STAFF_META_DATA, params);
    },
    getStaffMetaDataById(id) {
        return get(`${STAFF_META_DATA}/${id}`);
    },
    createStaffMetaData(data) {
        return post(`${STAFF_META_DATA}`, data);
    },
    updateStaffMetaData(id, data) {
        return put(`${STAFF_META_DATA}/${id}`, data);
    },
    updateStatusStaffMetaData(id, status) {
        return put(`${STAFF_META_DATA}/change-status/${id}`, status);
    },
    reportExportStaffMEdata(params) {
        return downloadFile(`${EXPORT_META_DATA}/staff`, params);
    },
    getInformation(id) {
        return get(`${STAFF_META_DATA}/info/${id}`);
    },
    uploadAvatar(id, file) {
        return putFormData(`hrm/upload/staff-meta-data/avatar/${id}`, file);
    },
    uploadFileStaffMeta(id, file) {
        return postUploadImage(`hrm/upload/staff-meta-data/${id}`, file);
    },
    deleteFileStaffMeta(id, params) {
        return del(`hrm/upload/staff-meta-data/delete/${id}`, params);
    },
    getBriefAllStaffMetaData(params) {
        return get(`${STAFF_META_DATA}/find-all-brief`, params);
    }
}